import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import Layout from "../components/layout";
import OrderButtons from "../components/orderButtons";

const MenuSection = styled.section`
  background-color: #ededed;
  margin: 0px 0px 20px 0px;
  padding: 20px 30px;
  border-radius: 5px;

  p {
    padding-bottom: 14px;

    @media only screen and (max-width: 600px) {
      border-bottom: 2px solid #ffffff;
      text-align: center;
    }
  }

  h4 {
    font-size: 30px;
    font-weight: 900;
    text-decoration: underline;
    @media only screen and (max-width: 600px) {
      text-align: center;
    }
  }

  b {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    @media only screen and (max-width: 600px) {
      padding-bottom: 8px;
    }
  }
`;

const OrderButtonsHolder = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px;

  a {
    background-color: darkred;
    color: white;
    padding: 10px;
    font-weight: 900;
    text-decoration: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
`;

// markup
const MenuPage = (data) => {
  return (
    <Layout title={"Menu"}>
      <OrderButtonsHolder>
        <OrderButtons />
      </OrderButtonsHolder>

      <MenuSection>
        <h4>Appetizers</h4>
        <p>
          <b>Fire Stix</b> 5 Fried Pepper Jack stix coated with Red and Blue
          Corn Panko crumbs. served with Ranch dressing. $9.95
        </p>

        <p>
          <b>Shrimp Cartel</b> 6 Spicy marinated shrimp tossed with Cartel Taco
          sauce. $13.95
        </p>

        <p>
          <b>Five Layer Dip</b> Refried Beans, Cheddar, Black Olives, Sour
          Cream, and Guacamole. $10.95. Add a protein for $2 extra.
        </p>

        <p>
          <b>Chips and Dips</b> Queso, Salsa or Guacamole. Served with Tortilla
          Chips and Chicharrones. $8.95. Get all 3 dips for $4 extra.
        </p>
        <p>
          <b>Fried Green Beans</b> Battered Green Beans with Ranch Dressing.
          $8.95
        </p>

        <p>
          <b>Chicken Flautas</b> served with Salsa $9.95. Add Guacamole for $2
          extra.
        </p>

        <p>
          <b>Bone in Wings</b>5 or 10 bone in wings sauced with Buffalo, BBQ,
          Cartel Sweet Spicy Jalapeno $8.95 / $16.95. Add Fries for $3 extra
        </p>

        <p>
          <b>Nacho Stacked Nachos</b>
          Tortilla Chips piled high with Cheese, Queso, Cotija Cheese and Pico
          De Gallo, Grilled Chicken or Steak $10.49. Add sour cream guacamole.
          $3
        </p>
      </MenuSection>

      <MenuSection>
        <h4>Tacos</h4>
        Served on 6 inch Flour or Corn Tortilla
        <p>
          <b>#1 - Fajita (Beef or Chicken)</b>
          Refried Beans, Cheese, Avocado, Aioli, Cilantro and Onioins $5.45
        </p>
        <p>
          <b>#2 - Pulled Pork</b>
          Lettuce, cilantro, Onions and BBQ sauce $4.95
        </p>
        <p>
          <b>#3 - Sauteed Shrimp</b>
          Coated in Cartel Butter with Black beans, Cilantro Rice and Avocado
          $5.95
        </p>
        <p>
          <b>#4 - Fish Taco</b>
          Cilantro Horse Radish Slaw, Fresh Avocado, Salsa Verde, Smoked Tomato
          Aioli & Cotija Cheese. $5.25
        </p>
        <p>
          <b>#5 - Grilled Zucchini</b>
          Tomatoes, Black beans, Mexican rice, Cotija Cheese and Tomato Aioli
          $4.95
        </p>
        <p>
          <b>#6 - Fried Chicken Tenders</b>
          Tossed in Cartel Butter, Cilantro Rice Topped with Cotija Cheese.
          $4.95
        </p>
        <p>
          <b>#7 - Grilled Chicken</b>
          Mac & Cheese, Bacon, Tomatoes, and Cotija Cheese $4.95
        </p>
      </MenuSection>

      <MenuSection>
        <h4>Cartel Classics</h4>
        Classics served with any of the following: Shrimp (Grilled or Fried),
        Ground Beef, Shredded Chicken, Grilled Chicken, or Steak.
        <p>
          <b>Tacos</b>6 inch flour or Corn Tortilla with Lettuce, Tomatoes,
          Cheese, and Cilantro. $2.99 / $3.99
        </p>
        <p>
          <b>Burritos and Bowls</b>
          12 inch flour tortilla or shredded lettuce, cilantro rice, black
          beans, guacamole and shredded cheese. $8.99 / $9.99
        </p>
        <p>
          <b>Quesadillas</b>
          Filled with shredded cheese, served with Pico de Gallo on the side.
          $10.99
        </p>
        <p>
          <b>Tostadas</b>3 Crisp 6 inch torillas topped with refried beans,
          lettuce, tomatoes and fresh jalapenos. $10.95
        </p>
      </MenuSection>

      <MenuSection>
        <h4>3 Birra Tacos</h4>
        Oven roasted Birria served with Cilantro, Onions and House Made
        Consomme. $9.49
      </MenuSection>

      <MenuSection>
        <h4>Salad</h4>

        <p>
          <b>Chop Salad</b>
          Lettuce Mix, Tomatoes, Onions, Cheddar Cheese, Black Beans, Cotija,
          and Crisp Tortilla Strips. $9.99. Dressings: Italian Verde or
          Buttermilk Ranch. Add a protein for $3
        </p>
      </MenuSection>

      <MenuSection>
        <h4>Breakfast - Served All Day</h4>

        <p>
          <b>Breakfast Tacos - Eggs and choise of:</b>
          Bacon, Chorizo, Birra, or Pork. $2.75. Add cheese for 50 cents.
        </p>

        <p>
          <b>El Jefe</b>
          Scrambled eggs, choice of protein, Grilled peppers and Onions. Choice
          of beans, potatoes, and cheese. $7.99
        </p>
      </MenuSection>

      <MenuSection>
        <h4>Lunch Specials - Everyday 10am - 2pm</h4>
        <p>
          <b>Any 2 Cartel Classic Tacos</b>
          served with 2 sides for $10.49
        </p>
        <p>
          <b>Cartel Burger and Fries</b>8 oz patty with Poblano Peppers and
          Cheese. $10.49
        </p>
        <p>
          <b>Extras</b>
          Refried Beans, Guacamole, Sour Cream, Queso and Pico De Gallo. $1
        </p>
        <p>
          <b>Sides</b>
          Refried Beans, Black Beans, Cilantro Rice, Mexican Rice and Spicy
          Street Corn. $3
        </p>
      </MenuSection>

      <MenuSection>
        <h4>Kids - 12 and under (includes drink)</h4>
        <p>
          <b>Cheese Quesadilla</b>
          $6.50
        </p>
        <p>
          <b>Chicken or Beef Crispy or Soft Taco</b>
          $6.50
        </p>
        <p>
          <b>Cheese Nachos</b>
          $6.50
        </p>
        <p>
          <b>Chicken Nuggets</b>
          $6.50
        </p>
      </MenuSection>
    </Layout>
  );
};

export const query = graphql`
  query MenuQuery {
    allSanitySpecial {
      nodes {
        description
        name
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default MenuPage;

import React from "react";

export default function orderButtons() {
  return (
    <>
      <a
        rel="noopener noreferrer"
        aria-label="Online Ordering"
        target="_blank"
        href={`//online.skytab.com/a85c868ccb20e3f80b69ba4420a6a94f`}
      >
        Order For Pickup
      </a>
      <a
        rel="noopener noreferrer"
        aria-label="Door Dash"
        target="_blank"
        href={`//www.doordash.com/store/cartel-taco-bar-arlington-790029/en-US`}
      >
        Door Dash
      </a>
      <a
        rel="noopener noreferrer"
        aria-label="Uber Eats"
        target="_blank"
        href={`//www.ubereats.com/dallas/food-delivery/cartel-taco-bar/YLKpXWQnTuiXAUEVafRT6w`}
      >
        UberEats
      </a>
    </>
  );
}
